import createApp from '@shopify/app-bridge'
import { AppLink, NavigationMenu, Redirect } from '@shopify/app-bridge/actions'
import { getSessionToken } from '@shopify/app-bridge-utils'

const SESSION_TOKEN_REFRESH_INTERVAL = 2000

document.addEventListener('turbolinks:request-start', function (event) {
  if ( window.sessionToken ) {
    const xhr = event.data.xhr
    xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionToken)
  }
})

jQuery(document).on('turbolinks:load', async function() {
  const shopifyApiKey = document.querySelector('meta[name="shopify_api_key"]')
  const shopifyHostMetaTag = document.querySelector('meta[name="shopify_host"]')

  if ( shopifyApiKey && shopifyHostMetaTag) {
    window.app = createApp({
      apiKey: shopifyApiKey.content,
      host: shopifyHostMetaTag.content,
      forceRedirect: true
    })

    // Wait for a session token before trying to load an authenticated page
    await retrieveToken(app)

    // Keep retrieving a session token periodically
    keepRetrievingToken(app)

    const moderationLink = AppLink.create(app, {
      label: 'Moderation',
      destination: '/shopify_app_bridge/moderation'
    })

    const collectionLink = AppLink.create(app, {
      label: 'Review Collection',
      destination: '/shopify_app_bridge/collection'
    })

    const customersLink = AppLink.create(app, {
      label: 'Customers',
      destination: '/shopify_app_bridge/customers'
    })

    const displayLink = AppLink.create(app, {
      label: 'Display',
      destination: '/shopify_app_bridge/display'
    })

    const marketingLink = AppLink.create(app, {
      label: 'Marketing',
      destination: '/shopify_app_bridge/marketing'
    })

    const productsLink = AppLink.create(app, {
      label: 'Products',
      destination: '/shopify_app_bridge/products'
    })

    const toolsLink = AppLink.create(app, {
      label: 'Tools',
      destination: '/shopify_app_bridge/tools'
    })

    const settingsLink = AppLink.create(app, {
      label: 'Settings',
      destination: '/shopify_app_bridge/settings'
    })

    NavigationMenu.create(app, {
      items: [
        moderationLink,
        collectionLink,
        customersLink,
        displayLink,
        marketingLink,
        productsLink,
        toolsLink,
        settingsLink
      ]
    })
  }
})

jQuery(document).on('turbolinks:load', function() {
  const shopifyApiKey = document.querySelector('meta[name="shopify_api_key"]')
  const shopifyHostMetaTag = document.querySelector('meta[name="shopify_host"]')

  if ( isSafari() && shopifyApiKey && shopifyHostMetaTag ) {
    // Page refresh (used by global discard button) needs to be handled via app-bridge to retain session
    function handleRedirect(url) {
      const redirect = Redirect.create(window.app);
      redirect.dispatch(Redirect.Action.APP, url);
    }

    $('form').on('submit', function(event) {
      event.preventDefault();
      event.stopPropagation();

      const form = $(this);
      const formData = new FormData(form[0]);

      if ( $('#store_logo').length ) {
        formData.append('logo', $('#store_logo').prop('files')[0]);
      }

      $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionToken)
        },
        success: function(data){
          hc_toast('Changes saved', 'success')
        },
        error: function(data){
          hc_toast('There was an error while submitting data. Please try again', 'error')
        }
      });
    });

    $("a[data-remote='true']").on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      $.ajax({
        url: $(this).attr('href'),
        method: $(this).data().method || 'POST',
        dataType: $(this).data().datatype || '',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionToken)
        },
      });
    });
  }
});

async function retrieveToken(app) {
  window.sessionToken = await getSessionToken(app)
}

function keepRetrievingToken(app) {
  setInterval(() => {
    retrieveToken(app)
  }, SESSION_TOKEN_REFRESH_INTERVAL)
}

function isSafari() {
  return /^((?!chrome|android|crios).)*safari/i.test(navigator.userAgent);
}
